
import Vue from 'vue'
import Component from 'vue-class-component'
import { payeeModule } from '@/store/payees/module'
import { Payee, CreatePayee, UpdatePayee } from '@/store/payees/types'

@Component
export default class PayeeMixin extends Vue {
  payees = payeeModule

  get allPayees(): Payee[] {
    return this.payees.payees
  }

  payeeName(payeeId: string | undefined | null): string {
    if (!payeeId) {
      return ''
    }
    const payee = this.payees.payee(payeeId)
    return payee ? payee.name || '' : ''
  }

  async createPayee(name: string): Promise<Payee> {
    return this.payees.createPayee(new CreatePayee(name))
  }

  async updatePayee(payee: UpdatePayee): Promise<Payee> {
    return this.payees.updatePayee(payee)
  }
}
