import { Entity } from '@/types'

export class Payee implements Entity {
  id?: string
  name?: string
  lastUsedCategoryIds?: string[]
  favorite?: boolean

  constructor(
    id: string,
    name: string,
    lastUsedCategoryIds: string[] = [],
    favorite: boolean = false
  ) {
    this.id = id
    this.name = name
    this.lastUsedCategoryIds = lastUsedCategoryIds
    this.favorite = favorite
  }
}

export class CreatePayee {
  name: string

  constructor(name: string) {
    this.name = name
  }
}

export class UpdatePayee {
  id: string
  name: string
  favorite: boolean

  constructor(id: string, name: string, favorite: boolean = false) {
    this.id = id
    this.name = name
    this.favorite = favorite
  }
}
