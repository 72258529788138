export enum Status {
  SCHEDULED = 'SCHEDULED',
  UNCLEARED = 'UNCLEARED',
  CLEARED = 'CLEARED',
  RECONCILED = 'RECONCILED'
}

export enum RepeatingInterval {
  NOT_REPEATING = 'NOT_REPEATING',
  MONTHLY = 'MONTHLY'
}

export class CategoryAmount {
  categoryId: string | null
  amount: number
  description?: string

  constructor(
    categoryId: string | null = null,
    amount: number = 0,
    description?: string
  ) {
    this.categoryId = categoryId
    this.amount = amount
    this.description = description
  }
}

export abstract class Transaction {
  id: string | null
  accountId: string | null
  date: string | null
  description: string | null
  status: Status
  repeatingInterval: RepeatingInterval

  constructor(
    id: string | null,
    date?: string,
    accountId?: string,
    description?: string,
    status: Status = Status.UNCLEARED,
    repeatingInterval: RepeatingInterval = RepeatingInterval.NOT_REPEATING
  ) {
    this.id = id
    this.date = date || null
    this.accountId = accountId || null
    this.description = description || null
    this.status = status
    this.repeatingInterval = repeatingInterval
  }

  abstract getType(): string

  abstract getSum(currentAccountId?: string): number
}

export class Payment extends Transaction {
  payeeId: string | null
  categoryAmounts: CategoryAmount[]

  constructor(
    id: string | null = null,
    date?: string,
    accountId?: string,
    payeeId?: string,
    categoryAmounts: CategoryAmount[] = [new CategoryAmount()],
    description?: string,
    status: Status = Status.UNCLEARED,
    repeatingInterval: RepeatingInterval = RepeatingInterval.NOT_REPEATING
  ) {
    super(id, date, accountId, description, status, repeatingInterval)
    this.payeeId = payeeId || null
    this.categoryAmounts = categoryAmounts
  }

  getType(): string {
    return 'Payment'
  }

  getSum(currentAccountId?: string): number {
    let result = 0
    this.categoryAmounts
      .map(ca => ca.amount)
      .forEach(amount => (result += amount || 0))
    return result
  }
}

export class Transfer extends Transaction {
  targetAccountId: string | null
  categoryId: string | null
  amount: number

  constructor(
    id: string | null = null,
    date?: string,
    accountId?: string,
    targetAccountId?: string,
    categoryId?: string,
    amount: number = 0,
    description?: string,
    status: Status = Status.UNCLEARED,
    repeatingInterval: RepeatingInterval = RepeatingInterval.NOT_REPEATING
  ) {
    super(id, date, accountId, description, status, repeatingInterval)
    this.targetAccountId = targetAccountId || null
    this.categoryId = categoryId || null
    this.amount = amount
  }

  getType(): string {
    return 'Transfer'
  }

  getSum(currentAccountId?: string): number {
    if (currentAccountId && currentAccountId === this.targetAccountId) {
      return -this.amount
    }
    return this.amount
  }
}
