


















import Component, { mixins } from 'vue-class-component'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import MTextField from '@/components/common/MTextField.vue'
import moment from 'moment'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { MTextField }
})
export default class DatePicker extends mixins(GlobalMixin) {
  @Prop() value!: string
  @Prop(Boolean) monthPicker!: boolean

  get computedDate(): Date {
    return moment(this.value).toDate()
  }

  set computedDate(value: Date) {
    this.$emit('input', moment(value).format('YYYY-MM-DD'))
  }
}
