































































import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { Entity } from '@/types'
import _ from 'lodash'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import MTextField from '@/components/common/MTextField.vue'
import Utils from '@/utils'

@Component({
  components: { MTextField }
})
export default class DesktopEntitySelector extends mixins(GlobalMixin) {
  @Prop() value!: string
  @Prop() data!: Entity[]
  @Prop() groupingFunction!: (entity: Entity) => string
  @Prop() name!: string
  @Prop() onCreateEntity!: (name: string) => Promise<Entity>
  @Prop() hint!: string
  @Prop() extraGroupingName?: string
  @Prop() extraGroupingFunction?: (entity: Entity) => boolean

  searchInput: string = this.selectedName
  isActive: boolean = false
  highlight: number = -1

  mounted(): void {
    this.searchInput = this.selectedName
  }

  get entitiesToDisplay(): [string, Entity[]][] {
    let groupBy
    if (this.groupingFunction) {
      groupBy = this.groupingFunction
    } else {
      groupBy = Utils.firstLetterGrouping
    }
    const groups = _.groupBy(this.filteredData, groupBy)
    const pairs = _.toPairs(groups)

    if (this.extraGroupingName && this.extraGroupingFunction) {
      const extraGrouping = this.filteredData.filter(this.extraGroupingFunction)
      if (extraGrouping.length > 0) {
        pairs.splice(0, 0, [this.extraGroupingName, extraGrouping])
      }
    }

    return pairs
  }

  get filteredData(): Entity[] {
    return this.data.filter(entity => {
      if (!entity.name) {
        return false
      }
      return entity.name
        .toLocaleLowerCase()
        .startsWith(this.searchInput.toLocaleLowerCase())
    })
  }

  get flattenedData(): Entity[] {
    if (!this.extraGroupingName || !this.extraGroupingFunction) {
      return this.filteredData
    }

    const extraEntries = this.filteredData.filter(this.extraGroupingFunction)

    return extraEntries.concat(this.filteredData)
  }

  get selectedName(): string {
    return this.entityName(this.newValue)
  }

  entityName(entityId: string): string {
    const entity = this.data.find(e => e.id === entityId)
    return entity ? entity.name || '' : ''
  }

  focus(): void {
    this.isActive = true
    const inputElement = (this.$refs[this.name + 'SearchField'] as any).$refs
      .input as HTMLInputElement
    inputElement.focus()
    inputElement.select()
    this.highlight = this.filteredData.length > 0 ? 0 : -1
  }

  onBlur(): void {
    this.searchInput = this.entityName(this.value)
    this.isActive = false
  }

  get offerCreateLink(): boolean {
    if (
      !this.onCreateEntity ||
      !this.searchInput ||
      this.searchInput.length === 0
    ) {
      return false
    }
    return (
      this.data.findIndex(entity => entity.name === this.searchInput) === -1
    )
  }

  isRowSelected(entity: Entity): boolean {
    return this.value === entity.id
  }

  get isCreateRowHighlighted(): boolean {
    return this.highlight === -1
  }

  isRowHighlighted(groupIndex: number, entityIndex: number): boolean {
    let entityNumber = 0
    for (let i = 0; i < groupIndex; i++) {
      entityNumber += this.entitiesToDisplay[i][1].length
    }
    entityNumber += entityIndex
    return this.highlight === entityNumber
  }

  doCreate(): void {
    this.onCreateEntity(this.searchInput).then(createdEntity => {
      if (createdEntity.id) {
        this.newValue = createdEntity.id
      }
    })
  }

  onEntitySelectedByIndex(index: number): void {
    const selectedEntity = this.flattenedData[index]
    if (selectedEntity.id) {
      this.newValue = selectedEntity.id
      this.searchInput = this.entityName(this.newValue)
    }
  }

  onEntitySelected(entity: Entity): void {
    if (entity.id) {
      this.newValue = entity.id
    }
  }

  onEnterPressed(): void {
    if (this.offerCreateLink && this.highlight === -1) {
      this.doCreate()
    } else if (this.highlight >= 0) {
      this.onEntitySelectedByIndex(this.highlight)
    }
  }

  onTabPressed(keyEvent: KeyboardEvent): void {
    this.onEnterPressed()
  }

  get newValue(): string {
    return this.value
  }

  set newValue(newValue: string) {
    this.$emit('input', newValue)
    this.searchInput = this.entityName(this.newValue)
  }

  @Watch('value')
  onValueChange(): void {
    this.searchInput = this.entityName(this.newValue)
  }

  onEscapePress(keyboardEvent: KeyboardEvent): void {
    this.searchInput = this.entityName(this.value)
    if (keyboardEvent.target) {
      const target = keyboardEvent.target as HTMLElement
      target.blur()
    }
  }

  changeHighlight(offset: number): void {
    this.highlight = this.highlight + offset
    this.highlight = Math.min(this.flattenedData.length - 1, this.highlight)
    this.highlight = Math.max(this.offerCreateLink ? -1 : 0, this.highlight)
    this.$nextTick(() => {
      this.scrollHighlightedIntoView(offset < 0)
    })
  }

  scrollHighlightedIntoView(downwards: boolean = false): void {
    const highlightElement = document.getElementById('highlighted')
    if (!highlightElement) {
      return
    }
    const titleRows = document.getElementsByClassName('title-row')
    const titleRowHeight = titleRows[0].clientHeight || 0
    if (this.isScrolledIntoView(highlightElement, titleRowHeight)) {
      return
    }

    const scrollingParent = highlightElement.parentElement
      ? highlightElement.parentElement.parentElement
      : null
    if (!scrollingParent) {
      return
    }

    if (downwards) {
      scrollingParent.scrollTop =
        highlightElement.offsetTop -
        scrollingParent.offsetHeight +
        highlightElement.offsetHeight
    } else {
      scrollingParent.scrollTop = highlightElement.offsetTop - titleRowHeight
    }
  }

  @Watch('searchInput')
  onSearchInputChanged(): void {
    this.highlight = this.filteredData.length > 0 ? 0 : -1
  }
}
