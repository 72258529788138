























































import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import MobileEntitySelector from './mobile/MobileEntitySelector.vue'
import DesktopEntitySelector from './desktop/DesktopEntitySelector.vue'
import { Category, CreateCategory } from '@/store/categories/types'
import { categoryModule } from '@/store/categories/module'
import { masterCategoryModule } from '@/store/mastercategories/module'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import CategoryMixin from '../mixins/CategoryMixin.vue'

@Component({
  components: { MobileEntitySelector, DesktopEntitySelector }
})
export default class CategorySelector extends mixins(
  GlobalMixin,
  CategoryMixin
) {
  @Prop() value!: string
  @Prop() rootClass!: string
  @Prop() searchbarClasses!: string
  @Prop() selectorClasses!: string
  @Prop({ default: false }) startActive!: boolean
  @Prop({ default: false }) rounded!: boolean

  masterCategoryId: string | null = null
  createCategoryObject: CreateCategory | null = null
  categories = categoryModule
  masterCategories = masterCategoryModule
  showCategorySelector = true

  get newValue(): string | null {
    return this.value || null
  }

  set newValue(newValue: string | null) {
    this.$emit('input', newValue)
  }

  onUnchanged(): void {
    this.$emit('unchanged')
  }

  mounted(): void {
    this.createCategoryObject = null
  }

  onCreateCategory(categoryName: string): Promise<Category> {
    this.showCategorySelector = false
    this.createCategoryObject = new CreateCategory(categoryName, null, null)
    this.$nextTick(() => {
      const masterCategorySelector = this.$refs
        .masterCategorySelector as DesktopEntitySelector
      masterCategorySelector.focus()
    })
    return Promise.resolve(new Category(undefined, categoryName))
  }

  onCreateMasterCategory(masterCategoryName: string): Promise<Category> {
    if (this.createCategoryObject) {
      this.createCategoryObject.newMasterCategoryName = masterCategoryName
      return this.categories
        .createCategory(this.createCategoryObject)
        .then(category => {
          this.showCategorySelector = true
          this.createCategoryObject = null
          if (category.id) {
            this.newValue = category.id
          }
          return category
        })
        .then(category => {
          this.masterCategories.loadAllMasterCategories()
          return category
        })
    }
    return Promise.reject(new Error('not a createCategoryObject'))
  }

  @Watch('masterCategoryId')
  onMasterCategoryIdSelected(newMasterCategoryId: string): void {
    if (this.createCategoryObject) {
      this.createCategoryObject.masterCategoryId = newMasterCategoryId
      this.categories
        .createCategory(this.createCategoryObject)
        .then(category => {
          this.createCategoryObject = null
          this.showCategorySelector = true
          if (category.id) {
            this.newValue = category.id
          }
        })
    }
  }

  get allCategories(): Category[] {
    return this.categories.categories.filter(
      c => c.masterCategoryId !== 'HIDDEN_CATEGORIES' || this.value === c.id
    )
  }

  get allMasterCategories(): Category[] {
    return this.masterCategories.masterCategories.filter(
      c => c.id !== 'HIDDEN_CATEGORIES'
    )
  }

  groupByMasterCategory(category: Category): string {
    if (!category.masterCategoryId) {
      return 'System'
    } else if (category.masterCategoryId === 'HIDDEN_CATEGORIES') {
      return 'Hidden'
    }
    return this.masterCategoryName(category.masterCategoryId)
  }
}
