








































import { Prop, Component } from 'vue-property-decorator'
import MButton from '@/components/common/MButton.vue'
import { mixins } from 'vue-class-component'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import { ButtonType } from './types'

@Component({
  components: { MButton }
})
export default class MClearableButton extends mixins(GlobalMixin) {
  @Prop() triggerLabel!: string
  @Prop() icon!: string
  @Prop() onClearCallback!: () => void
  @Prop() isActive!: boolean

  ButtonType = ButtonType

  toggle(): void {
    const dropdown = this.$refs.dropdown as any
    dropdown.toggle()
  }
}
