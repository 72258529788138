






















































import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { Entity } from '@/types'
import _ from 'lodash'
import GlobalMixin from '@/mixins/GlobalMixin.vue'
import Utils from '@/utils'
import MTextField from '@/components/common/MTextField.vue'

@Component({
  components: { MTextField }
})
export default class MobileEntitySelector extends mixins(GlobalMixin) {
  @Prop() value!: string
  @Prop() data!: Entity[]
  @Prop() groupingFunction!: (entity: Entity) => string
  @Prop() name!: string
  @Prop() onCreateEntity!: (name: string) => Promise<Entity>
  @Prop() hint!: string
  @Prop() extraGroupingName?: string
  @Prop() extraGroupingFunction?: (entity: Entity) => boolean

  searchInput: string = this.selectedName
  dirty: boolean = false

  get entitiesToDisplay(): [string, Entity[]][] {
    let groupBy
    if (this.groupingFunction) {
      groupBy = this.groupingFunction
    } else {
      groupBy = Utils.firstLetterGrouping
    }
    const groups = _.groupBy(this.filteredData, groupBy)
    const pairs = _.toPairs(groups)

    if (this.extraGroupingName && this.extraGroupingFunction) {
      const extraGrouping = this.filteredData.filter(this.extraGroupingFunction)
      if (extraGrouping.length > 0) {
        pairs.splice(0, 0, [this.extraGroupingName, extraGrouping])
      }
    }

    return pairs
  }

  get filteredData(): Entity[] {
    if (!this.dirty) {
      return this.data
    }
    return this.data.filter(entity => {
      if (!entity.name) {
        return false
      }
      return entity.name
        .toLocaleLowerCase()
        .startsWith(this.searchInput.toLocaleLowerCase())
    })
  }

  get selectedName(): string {
    return this.entityName(this.newValue)
  }

  entityName(entityId: string): string {
    const entity = this.data.find(e => e.id === entityId)
    return entity ? entity.name || '' : ''
  }

  get offerCreateLink(): boolean {
    if (
      !this.onCreateEntity ||
      !this.searchInput ||
      this.searchInput.length === 0
    ) {
      return false
    }
    return (
      this.data.findIndex(entity => entity.name === this.searchInput) === -1
    )
  }

  isRowSelected(entity: Entity): boolean {
    return this.value === entity.id
  }

  doCreate(): void {
    this.onCreateEntity(this.searchInput).then(createdEntity => {
      if (createdEntity.id) {
        this.newValue = createdEntity.id
      }
    })
  }

  onEntitySelected(entity: Entity): void {
    if (entity.id) {
      if (entity.id === this.newValue) {
        this.$emit('unchanged')
      }
      this.newValue = entity.id
    }
  }

  focus(): void {
    const inputElement = (this.$refs.searchField as any).$refs
      .input as HTMLInputElement
    inputElement.focus()
    inputElement.select()
  }

  get newValue(): string {
    return this.value
  }

  set newValue(newValue: string) {
    if (newValue !== this.value) {
      this.$emit('input', newValue)
      this.searchInput = ''
      this.dirty = false
    }
  }

  @Watch('newValue')
  onNewValueChange(): void {
    this.$emit('input', this.newValue)
    this.searchInput = ''
    this.dirty = false
  }

  @Watch('value')
  onValueChange(): void {
    this.newValue = this.value
    this.searchInput = this.entityName(this.newValue)
    this.dirty = false
  }

  @Watch('searchInput')
  onSearchInputChange(): void {
    this.dirty = true
  }

  mounted(): void {
    this.newValue = this.value
    this.searchInput = this.selectedName
    this.dirty = false
    this.scrollSelectedIntoView()
  }

  scrollSelectedIntoView(): void {
    const selectElement = document.getElementById('selected')
    if (!selectElement) {
      return
    }
    const titleRows = document.getElementsByClassName('title-row')
    const titleRowHeight = titleRows[0].clientHeight || 0
    if (this.isScrolledIntoView(selectElement, titleRowHeight)) {
      return
    }

    const scrollingParent = selectElement.parentElement
      ? selectElement.parentElement.parentElement
      : null
    if (!scrollingParent) {
      return
    }
    scrollingParent.scrollTop =
      selectElement.offsetTop -
      scrollingParent.offsetHeight +
      selectElement.offsetHeight
  }
}
