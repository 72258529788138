import { AxiosPromise } from 'axios'
import axios from '@/backend/axios'
import AccountModule, { accountModule } from './store/accounts/module'
import { Transaction, Transfer, Payment } from './store/transactions/types'
import PayeeModule, { payeeModule } from '@/store/payees/module'
import router from '@/router'
import VueRouter from 'vue-router'
import _ from 'lodash'
import { DateRange } from './types'
import moment from 'moment'

export default interface TransactionStorage {
  findAll(
    page: number,
    pageSize: number,
    sortField: string,
    sortDirection: string,
    accountId?: string,
    filterCategories?: string[],
    filterStatuses?: string[],
    dateRange?: DateRange
  ): AxiosPromise<Array<Transaction>>
  countTransactions(
    accountId?: string,
    filterCategories?: string[],
    filterStatuses?: string[],
    dateRange?: DateRange
  ): AxiosPromise<number>
  findOne(transactionId: string): AxiosPromise<Transaction>
  createPayment(payment: Payment): AxiosPromise<Payment>
  createTransfer(transfer: Transfer): AxiosPromise<Transfer>
  updatePayment(
    updatedPayment: Payment,
    original: Payment
  ): AxiosPromise<Payment>
  updateTransfer(
    updatedTransfer: Transfer,
    original: Transfer
  ): AxiosPromise<Transfer>
  delete(transactions: Array<Transaction>): AxiosPromise<void>
}

export interface QueryParam {
  key: string
  value: string
}

class RealTransactionStorage implements TransactionStorage {
  private httpClient: any = null
  private accounts: AccountModule
  private payees: PayeeModule
  private actualRouter: VueRouter

  public constructor(
    httpClient: any,
    accounts: AccountModule,
    payees: PayeeModule,
    routerArg: VueRouter
  ) {
    this.httpClient = httpClient
    this.accounts = accounts
    this.payees = payees
    this.actualRouter = routerArg
  }

  public findAll(
    page: number,
    pageSize: number,
    sortField: string,
    sortDirection: string,
    accountId?: string,
    filterCategories: string[] = [],
    filterStatuses: string[] = [],
    dateRange?: DateRange
  ): AxiosPromise<Array<Transaction>> {
    const budgetId = this.actualRouter.currentRoute.params.budgetId
    let url = accountId
      ? `${budgetId}/${accountId}/transactions`
      : `${budgetId}/transactions`
    url += `?page=${page}&size=${pageSize}&sortField=${sortField}&sortDirection=${sortDirection}`
    if (filterCategories.length > 0) {
      const categories = filterCategories.join(',')
      url += `&categories=${categories}`
    }
    if (filterStatuses.length > 0) {
      const statuses = filterStatuses.join(',')
      url += `&statuses=${statuses}`
    }
    if (dateRange && dateRange.from) {
      const from = moment(dateRange.from).format('YYYY-MM-DD')
      url += `&from=${from}`
    }
    if (dateRange && dateRange.until) {
      const until = moment(dateRange.until).format('YYYY-MM-DD')
      url += `&until=${until}`
    }
    return this.httpClient({
      url: url
    })
  }

  public countTransactions(
    accountId?: string,
    filterCategories?: string[],
    filterStatuses: string[] = [],
    dateRange?: DateRange
  ): AxiosPromise<number> {
    const budgetId = this.actualRouter.currentRoute.params.budgetId
    let url = accountId
      ? `${budgetId}/${accountId}/transactions/count`
      : `${budgetId}/transactions/count`

    const queryParams: string[] = []
    if (filterCategories && filterCategories.length > 0) {
      const categories = filterCategories.join(',')
      queryParams.push(`categories=${categories}`)
    }
    if (filterStatuses && filterStatuses.length > 0) {
      const statuses = filterStatuses.join(',')
      queryParams.push(`statuses=${statuses}`)
    }
    if (dateRange && dateRange.from) {
      const from = moment(dateRange.from).format('YYYY-MM-DD')
      queryParams.push(`from=${from}`)
    }
    if (dateRange && dateRange.until) {
      const until = moment(dateRange.until).format('YYYY-MM-DD')
      queryParams.push(`until=${until}`)
    }
    if (queryParams.length > 0) {
      url += '?'
      url += queryParams.join('&')
    }
    return this.httpClient({
      url: url
    })
  }

  findOne(transactionId: string): AxiosPromise<Transaction> {
    const budgetId = this.actualRouter.currentRoute.params.budgetId
    return this.httpClient({
      url: `${budgetId}/transactions/${transactionId}`
    })
  }

  public createPayment(payment: Payment): AxiosPromise<Payment> {
    const budgetId = this.actualRouter.currentRoute.params.budgetId
    const restUrl = `${budgetId}/payments`
    return this.httpClient({
      method: 'post',
      url: restUrl,
      data: payment
    }).then((response: { data: Payment }) => {
      const createdPayment: Payment = response.data
      if (createdPayment.accountId) {
        this.accounts.loadBalance(createdPayment.accountId)
      }
      if (createdPayment.payeeId) {
        this.payees.loadAllPayees()
      }
      return response
    })
  }

  public createTransfer(transfer: Transfer): AxiosPromise<Transfer> {
    const budgetId = this.actualRouter.currentRoute.params.budgetId
    const restUrl = `${budgetId}/transfers`
    return this.httpClient({
      method: 'post',
      url: restUrl,
      data: transfer
    }).then((response: { data: Transfer }) => {
      const createdTransfer: Transfer = response.data
      if (createdTransfer.accountId) {
        this.accounts.loadBalance(createdTransfer.accountId)
      }
      if (createdTransfer.targetAccountId) {
        this.accounts.loadBalance(createdTransfer.targetAccountId)
      }
      return response
    })
  }

  public updatePayment(
    updatedPayment: Payment,
    original: Payment
  ): AxiosPromise<Payment> {
    const budgetId = this.actualRouter.currentRoute.params.budgetId
    const restUrl = `${budgetId}/payments`
    return this.httpClient({
      method: 'put',
      url: restUrl,
      data: updatedPayment
    }).then((response: { data: Payment }) => {
      const updatedPayment: Payment = response.data
      const accountsToUpdate = new Set<string>()
      if (original.accountId !== updatedPayment.accountId) {
        if (original.accountId) {
          accountsToUpdate.add(original.accountId)
        }
        if (updatedPayment.accountId) {
          accountsToUpdate.add(updatedPayment.accountId)
        }
      } else if (
        original.accountId &&
        !_.isEqual(original.categoryAmounts, updatedPayment.categoryAmounts)
      ) {
        accountsToUpdate.add(original.accountId)
      } else if (
        original.accountId &&
        !_.isEqual(original.status, updatedPayment.status)
      ) {
        accountsToUpdate.add(original.accountId)
      }

      accountsToUpdate.forEach(accountId =>
        this.accounts.loadBalance(accountId)
      )
      if (updatedPayment.payeeId) {
        this.payees.loadAllPayees()
      }
      return response
    })
  }

  public updateTransfer(
    updatedTransfer: Transfer,
    original: Transfer
  ): AxiosPromise<Transfer> {
    const budgetId = this.actualRouter.currentRoute.params.budgetId
    const restUrl = `${budgetId}/transfers`
    return this.httpClient({
      method: 'put',
      url: restUrl,
      data: updatedTransfer
    }).then((response: { data: Transfer }) => {
      const updatedTransfer: Transfer = response.data
      const accountsToUpdate = new Set<string>()
      if (original.accountId !== updatedTransfer.accountId) {
        if (original.accountId) {
          accountsToUpdate.add(original.accountId)
        }
        if (updatedTransfer.accountId) {
          accountsToUpdate.add(updatedTransfer.accountId)
        }
      } else if (
        original.accountId &&
        !_.isEqual(original.amount, updatedTransfer.amount)
      ) {
        accountsToUpdate.add(original.accountId)
      }

      if (original.targetAccountId !== updatedTransfer.targetAccountId) {
        if (original.targetAccountId) {
          accountsToUpdate.add(original.targetAccountId)
        }
        if (updatedTransfer.targetAccountId) {
          accountsToUpdate.add(updatedTransfer.targetAccountId)
        }
      } else if (
        original.targetAccountId &&
        !_.isEqual(original.amount, updatedTransfer.amount)
      ) {
        accountsToUpdate.add(original.targetAccountId)
      }

      if (
        original.accountId &&
        !_.isEqual(original.status, updatedTransfer.status)
      ) {
        accountsToUpdate.add(original.accountId)
      }

      accountsToUpdate.forEach(accountId =>
        this.accounts.loadBalance(accountId)
      )
      return response
    })
  }

  public delete(transactions: Array<Transaction>): AxiosPromise<void> {
    const budgetId = this.actualRouter.currentRoute.params.budgetId
    const idsToDelete = transactions.map(transaction => transaction.id)
    return this.httpClient({
      method: 'put',
      url: `${budgetId}/transactions/delete`,
      data: { idsToDelete: idsToDelete }
    }).then(() => {
      const accountsToUpdate = new Set<string>()
      transactions.forEach(transaction => {
        if (transaction.accountId) {
          accountsToUpdate.add(transaction.accountId)
        }
        if (transaction instanceof Transfer && transaction.targetAccountId) {
          accountsToUpdate.add(transaction.targetAccountId)
        }
      })
      accountsToUpdate.forEach(accountId =>
        this.accounts.loadBalance(accountId)
      )
    })
  }
}

export const createTransactionStorage = (
  httpClient = axios,
  accounts = accountModule,
  payees = payeeModule,
  routerArg = router
): TransactionStorage => {
  return new RealTransactionStorage(httpClient, accounts, payees, routerArg)
}

export const defaultTransactionStorage = createTransactionStorage()
