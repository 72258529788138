
import Vue from 'vue'
import Component from 'vue-class-component'
import { categoryModule } from '@/store/categories/module'
import { masterCategoryModule } from '@/store/mastercategories/module'
import { Category, CreateCategory } from '@/store/categories/types'
import { Transaction, Payment, Transfer } from '../store/transactions/types'
import { MasterCategory } from '../store/mastercategories/types'

@Component
export default class CategoryMixin extends Vue {
  categories = categoryModule
  masterCategories = masterCategoryModule

  get allCategories(): Category[] {
    return this.categories.categories
  }

  get allMasterCategories(): MasterCategory[] {
    return this.masterCategories.masterCategories
  }

  categoryName(categoryId: string | undefined | null): string {
    if (!categoryId) {
      return ''
    }
    const category = this.categories.category(categoryId)
    return category ? category.name || '' : ''
  }

  masterCategoryName(masterCategoryId: string | undefined | null): string {
    if (!masterCategoryId) {
      return ''
    }
    const masterCategory = this.masterCategories.masterCategory(
      masterCategoryId
    )
    return masterCategory ? masterCategory.name || '' : ''
  }

  async createCategory(createCategory: CreateCategory): Promise<Category> {
    return this.categories.createCategory(createCategory)
  }

  transactionCategoryName(transaction: Transaction): string {
    if (transaction instanceof Payment) {
      const categoryAmounts = transaction.categoryAmounts
      if (!categoryAmounts) {
        return ''
      }
      if (categoryAmounts.length === 1) {
        return categoryAmounts[0].categoryId
          ? this.categoryName(categoryAmounts[0].categoryId)
          : ''
      } else {
        return `Split - ${categoryAmounts.length} categories`
      }
    } else if (transaction instanceof Transfer) {
      return this.categoryName(transaction.categoryId)
    } else {
      return ''
    }
  }
}
