











































































import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import MButton from '@/components/common/MButton.vue'
import { ButtonType } from '@/components/common/types'
import MClearableButton from '@/components/common/MClearableButton.vue'
import { DateRange } from '@/types'
import moment, { Moment } from 'moment'
import _ from 'lodash'
import GlobalMixin from '@/mixins/GlobalMixin.vue'

@Component({
  components: { MButton, MClearableButton }
})
export default class DateFilter extends mixins(GlobalMixin) {
  @Prop() value!: DateRange
  @Prop() monthpicker!: boolean

  ButtonType = ButtonType

  newValue: DateRange = _.cloneDeep(this.value) || new DateRange()

  @Watch('value')
  onValueChange(value: DateRange): void {
    this.newValue = _.cloneDeep(value)
  }

  onApplyClicked(): void {
    if (!_.isEqual(this.newValue, this.value)) {
      this.$emit('input', this.newValue)
    }
    const mClearableDateButton = this.$refs
      .mClearableDateButton as MClearableButton
    mClearableDateButton.toggle()
  }

  onClearClicked(): void {
    this.newValue = new DateRange()
    this.onApplyClicked()
  }

  onActiveChanged(isActive: boolean): void {
    if (isActive) {
      this.newValue = _.cloneDeep(this.value)
    }
  }

  onDeleteFiltersClicked(event: any): void {
    this.$emit('input', new DateRange())
  }

  onYearClicked(offset: number = 0): void {
    this.newValue.from = moment()
      .startOf('year')
      .add(offset, 'year')
      .toDate()
    this.newValue.until = moment()
      .endOf('year')
      .add(offset, 'year')
      .toDate()
    this.onApplyClicked()
  }

  onMonthClicked(offset: number = 0): void {
    this.newValue.from = moment()
      .startOf('month')
      .add(offset, 'month')
      .toDate()
    this.newValue.until = moment()
      .endOf('month')
      .add(offset, 'month')
      .toDate()
    this.onApplyClicked()
  }

  get triggerLabel(): string {
    if (this.value.from && this.value.until) {
      return this.fromAndUntilLabel(this.value.from, this.value.until)
    }
    if (this.value.from) {
      return this.fromLabel(this.value.from)
    }
    if (this.value.until) {
      return this.untilLabel(this.value.until)
    }
    return 'All Time'
  }

  fromAndUntilLabel(from: Date, until: Date): string {
    const fromMoment = moment(from)
    const untilMoment = moment(until)
    if (fromMoment.isSame(untilMoment, 'day')) {
      return this.singleDayLabel(from)
    }
    if (this.isEntireMonth(fromMoment, untilMoment)) {
      return fromMoment.format('MMM YYYY')
    }
    if (this.isEntireYear(fromMoment, untilMoment)) {
      return fromMoment.format('YYYY')
    }
    return `${fromMoment.format('YYYY-MM-DD')} — ${untilMoment.format(
      'YYYY-MM-DD'
    )}`
  }

  isEntireMonth(fromMoment: Moment, untilMoment: Moment): boolean {
    return (
      fromMoment.isSame(untilMoment, 'month') &&
      fromMoment.isSame(fromMoment.clone().startOf('month'), 'day') &&
      untilMoment.isSame(untilMoment.clone().endOf('month'), 'day')
    )
  }

  isEntireYear(fromMoment: Moment, untilMoment: Moment): boolean {
    return (
      fromMoment.isSame(untilMoment, 'year') &&
      fromMoment.isSame(fromMoment.clone().startOf('year'), 'day') &&
      untilMoment.isSame(untilMoment.clone().endOf('year'), 'day')
    )
  }

  singleDayLabel(day: Date): string {
    const dayMoment = moment(day)
    if (dayMoment.isSame(new Date(), 'day')) {
      return 'Today'
    }
    if (dayMoment.isSame(moment().subtract(1, 'days'), 'day')) {
      return 'Yesterday'
    }
    return moment(day).format('YYYY-MM-DD')
  }

  fromLabel(from: Date): string {
    const formattedFrom = moment(from).format('YYYY-MM-DD')
    return `From ${formattedFrom}`
  }

  untilLabel(until: Date): string {
    const formattedUntil = moment(until).format('YYYY-MM-DD')
    return `Until ${formattedUntil}`
  }

  get isActive(): boolean {
    return !!this.value.from || !!this.value.until
  }
}
