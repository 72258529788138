
import Component, { mixins } from 'vue-class-component'
import _ from 'lodash'
import TransactionStorage, {
  defaultTransactionStorage
} from '../TransactionStorage'
import {
  Payment,
  Transaction,
  Transfer,
  Status
} from '@/store/transactions/types'
import { AxiosPromise } from 'axios'
import AccountMixin from '@/mixins/AccountMixin.vue'

@Component
export default class TransactionEditingMixin extends mixins(AccountMixin) {
  transactionStorage: TransactionStorage = defaultTransactionStorage
  paymentBeingEdited: Payment | null = null
  paymentOriginal: Payment | null = null
  transferBeingEdited: Transfer | null = null
  transferOriginal: Transfer | null = null

  get currentBudgetId(): string {
    return this.$route.params.budgetId
  }

  get currentTransactionId(): string | null {
    const transactionId = this.$route.params.transactionId
    return transactionId === 'new' ? null : transactionId
  }

  cancelEdit(): void {
    this.paymentBeingEdited = null
    this.paymentOriginal = null
    this.transferBeingEdited = null
    this.transferOriginal = null
  }

  async saveEdit(): Promise<boolean> {
    if (this.paymentBeingEdited) {
      if (this.paymentOriginal) {
        return this.toPromise(
          this.transactionStorage.updatePayment(
            this.paymentBeingEdited,
            this.paymentOriginal
          )
        )
      } else {
        return this.toPromise(
          this.transactionStorage.createPayment(this.paymentBeingEdited)
        )
      }
    } else if (this.transferBeingEdited) {
      if (this.transferOriginal) {
        return this.toPromise(
          this.transactionStorage.updateTransfer(
            this.transferBeingEdited,
            this.transferOriginal
          )
        )
      } else {
        return this.toPromise(
          this.transactionStorage.createTransfer(this.transferBeingEdited)
        )
      }
    }
    return false
  }

  async updateTransaction(
    updatedTransaction: Transaction,
    originalTransaction: Transaction
  ): Promise<boolean> {
    if (originalTransaction.getType() === 'Payment') {
      return this.toPromise(
        this.transactionStorage.updatePayment(
          updatedTransaction as Payment,
          originalTransaction as Payment
        )
      )
    } else {
      return this.toPromise(
        this.transactionStorage.updateTransfer(
          updatedTransaction as Transfer,
          originalTransaction as Transfer
        )
      )
    }
  }

  private toPromise(axiosPromise: AxiosPromise<Transaction>): Promise<boolean> {
    return Promise.resolve(
      axiosPromise
        .then(response => {
          if (response.status >= 400) {
            this.showError('Error: ' + response.status)
            return false
          }
          return true
        })
        .catch(() => false)
    )
  }

  showError(message: string): void {
    this.$buefy.snackbar.open({
      duration: 2000,
      message: message,
      type: 'is-danger',
      position: 'is-bottom'
    })
  }

  isTransactionComplete(): boolean {
    if (this.paymentBeingEdited) {
      return this.isPaymentComplete(this.paymentBeingEdited)
    } else if (this.transferBeingEdited) {
      return this.isTransferComplete(this.transferBeingEdited)
    }
    return false
  }

  isPaymentComplete(payment: Payment): boolean {
    if (
      !payment.date ||
      !payment.accountId ||
      !payment.status ||
      !payment.payeeId ||
      !payment.categoryAmounts
    ) {
      return false
    }

    for (const categoryAmount of payment.categoryAmounts) {
      if (!categoryAmount.categoryId || !categoryAmount.amount) {
        return false
      }
    }
    return true
  }

  isTransferComplete(transfer: Transfer): boolean {
    if (
      !transfer.date ||
      !transfer.accountId ||
      !transfer.status ||
      !transfer.targetAccountId ||
      !transfer.amount
    ) {
      return false
    }

    const account = this.accounts.account(transfer.accountId)
    if (!account) {
      return false
    }

    const targetAccount = this.accounts.account(transfer.targetAccountId)
    if (!targetAccount) {
      return false
    }

    if (account.offBudget === targetAccount.offBudget) {
      return !transfer.categoryId
    } else {
      return !!transfer.categoryId
    }
  }

  onToggleStatus(transaction: Transaction): Promise<void> {
    if (
      transaction.status === Status.SCHEDULED ||
      transaction.status === Status.RECONCILED
    ) {
      return Promise.resolve()
    }

    const updatedTransaction = _.cloneDeep(transaction)
    if (transaction.status === Status.UNCLEARED) {
      updatedTransaction.status = Status.CLEARED
    } else {
      updatedTransaction.status = Status.UNCLEARED
    }

    return this.updateTransaction(updatedTransaction, transaction).then(
      result => {
        if (result) {
          transaction.status = updatedTransaction.status
        }
      }
    )
  }
}
